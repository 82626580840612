

import { definitions } from '$lib/types'
import { writable } from 'svelte/store'


const userState: definitions['users'] | undefined = {} as definitions['users']
const store = writable(userState)
const { subscribe, update, set } = store

export const userStore = {
	subscribe,

	updateCurrentUser(newUserState: definitions['users'] | null) {
		update((state) => {

			const newState = newUserState ? {
				...state, ...newUserState
			} : undefined
			return newState!
		})
	},
	resetUser() {
		this.updateCurrentUser(null)
	}
}
